
import { defineComponent } from 'vue'
import useFilterPopup from '@/composable/useFilterPopup'

export default defineComponent({
  props: {
    keyName: String,
    valueName: String
  },
  setup() {
    const { isOpen, togglePopup, btn, popup } = useFilterPopup()

    return {
      isOpen,
      togglePopup,
      btn,
      popup
    }
  }
})
