
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    }
  },
  setup() {
    return {}
  }
})
