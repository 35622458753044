
import { defineComponent, reactive, ref } from 'vue'
import '@progress/kendo-ui/js/kendo.dropdownlist'
import { DropDownList } from '@progress/kendo-dropdowns-vue-wrapper'
import BaseInput from '@/components/Input/BaseInput.vue'
import BaseCheckbox from '@/components/Checkbox/BaseCheckbox.vue'
import { DateTimePicker } from '@progress/kendo-vue-dateinputs'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'

export default defineComponent({
  components: {
    DropDownList,
    BaseInput,
    BaseCheckbox,
    DateTimePicker,
    FeildInputGroup
  },
  setup() {
    // 區域欄位
    const siteArray = ref([
      { id: 0, title: 'Taipei Office' },
      { id: 1, title: 'San Jose Office' }
    ])
    // 網段欄位
    const networkArray = ref([
      { id: 0, title: '業務課' },
      { id: 1, title: '資訊室' },
      { id: 2, title: '會計室' },
      { id: 3, title: 'VLAN1' }
    ])
    const ipv4Array = Array(255)
      .fill({})
      .map((_, idx) => ({ id: idx + 1, title: `192.168.12.${idx + 1}` }))

    // MAC 資料
    const macData = reactive({
      site: 0,
      macAddress: '',
      hostName: '',
      privilegedMac: true,
      limitedTimeAuth: true
    })

    // IP 資料
    const ipData = reactive({
      network: 0,
      ipv4: 1,
      isIpv4Static: true,
      isIpv4BanChange: false,
      ipv6: '2001:b030:1228:1002::',
      isIpv6Static: true,
      isIpv6BanChange: false
    })

    const extensionData = reactive({
      visiterName: '',
      department: '',
      userSupervisor: '',
      userExtension: '',
      affiliation: '',
      antivirusVersion: '',
      virusPattern: '',
      antivirusLastUpdateTime: '',
      rMPInstall: '',
      propertyNumber: ''
    })

    const submitForm = () => {
      console.log('macData:', macData)
      console.log('ipData:', ipData)
      console.log('extensionData:', extensionData)
    }

    return {
      siteArray,
      macData,
      submitForm,
      networkArray,
      ipData,
      ipv4Array,
      extensionData
    }
  }
})
