
import { defineComponent, markRaw, onMounted, ref } from 'vue'
import '@progress/kendo-ui/js/kendo.treeview'
import { TreeView } from '@progress/kendo-treeview-vue-wrapper'
import '@progress/kendo-ui/js/kendo.data'
// import { HierarchicalDataSource } from '@progress/kendo-datasource-vue-wrapper'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import ControlDropdown from '@/components/Control/ControlDropdown.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import FilterUnitRadio from '@/components/Filter/FilterUnitRadio.vue'
import useFilterRadio from '@/composable/useFilterRadio'
import FilterUnitCheckbox from '@/components/Filter/FilterUnitCheckbox.vue'
import useFilterCheckbox from '@/composable/useFilterCheckbox'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterUnitList from '@/composable/useFilterUnitList'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInHost from '@/composable/useGridInHost'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import '@progress/kendo-ui/js/kendo.menu'
import { ContextMenu } from '@progress/kendo-layout-vue-wrapper'
import ContextMenuItem from '@/components/ContextMenu/ContextMenuItem.vue'
import '@progress/kendo-ui/js/kendo.dialog'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import DisplaySettingForm from '@/components/Form/HostPage/DisplaySettingForm.vue'
import RegisterSingleHostForm from '@/components/Form/HostPage/RegisterSingleHostForm.vue'
import RegisterMultiHostForm from '@/components/Form/HostPage/RegisterMultiHostForm.vue'

import {
  treeViewData,
  treeViewSchema,
  searchTypeRadioOptions,
  searchTypeInitialValue,
  hostCheckboxOptions,
  hostInitialCheckedIds,
  ipCheckboxOptions,
  ipInitialCheckedIds,
  keyWithOptions,
  initialFilterUnitList
} from '@/mock/data/host'

export default defineComponent({
  components: {
    TreeView,
    ControlWrapper,
    ControlButton,
    ControlDropdown,
    FilterWrapper,
    FilterTextInput,
    FilterUnitRadio,
    FilterUnitCheckbox,
    FilterUnitDeletable,
    FilterUnitAdd,
    GridWrapper,
    Grid,
    GridPageInfo,
    ContextMenu,
    ContextMenuItem,
    Dialog,
    DisplaySettingForm,
    RegisterSingleHostForm,
    RegisterMultiHostForm
  },
  setup() {
    // TreeView 相關
    // 注意！目前先使用 localDataSource 作法，待後端 API 完成請使用 remote HierarchicalDataSource

    /* template 目的為依據 item.icons 欄位渲染 icon，例如：<span class="tree-icon tree-i-site"></span>
     * .tree-i-* 須額外定義，檔案位置： '@/assets/styles/global/modify/kendo/_treeview.scss'
     */
    const template =
      '# for (i = 0; i < item.icons.length; i++ ) { #' +
      '<span class="tree-icon ' +
      '#: item.icons[i] #' +
      '"></span>' +
      '# } #' +
      '#: item.name #' +
      '<span class="child-count">' +
      '#: Number(item.count).toLocaleString() #' + // 1000 -> "1,000"
      '</span>'

    const localDataSource = markRaw(
      // eslint-disable-next-line
        new kendo.data.HierarchicalDataSource({
        data: treeViewData,
        schema: treeViewSchema
      })
    )

    const isTreeViewOpen = ref(true)

    // treeview template refs
    const treeViewRef = ref(null)
    // 取得 checked node 的 id
    const getCheckedNodeIds = (nodes, checkedNodes) => {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].checked) {
          checkedNodes.push(nodes[i].id)
        }

        if (nodes[i].hasChildren) {
          getCheckedNodeIds(nodes[i].children.view(), checkedNodes)
        }
      }
    }
    // treeview onCheck 處理
    // CHECK: (https://www.telerik.com/kendo-vue-ui/components/treeview/checkboxes/)
    const onTreeViewCheck = () => {
      //@ts-ignore
      const treeView = treeViewRef.value.kendoWidget()
      // 被選取的 nodes
      const checkedNodes = []

      getCheckedNodeIds(treeView.dataSource.view(), checkedNodes)
      if (checkedNodes.length > 0) {
        console.log(
          'IDs of checked nodes in treeview: ' + checkedNodes.join(',')
        )
        // FIXME: 已取得 checkedNodes，可以用來作後續的查找
      } else {
        console.log('No nodes checked in treeview.')
      }
    }

    // 篩選相關
    // 文字輸入
    const textInput = ref('')
    // 檢索 Radio 篩選
    // const searchTypeValue = ref(searchTypeInitialValue)
    const {
      currentValue: searchTypeValue,
      currentName: searchTypeName
    } = useFilterRadio(searchTypeRadioOptions, searchTypeInitialValue)
    // 主機狀態 checkbox 篩選
    const {
      allChecked: hostAllChecked,
      checkedIds: hostCheckIds,
      onCheck: hostOnCheck,
      checkAll: hostCheckAll
    } = useFilterCheckbox(hostCheckboxOptions, hostInitialCheckedIds)
    // IP 狀態 checkbox 篩選
    const {
      allChecked: ipAllChecked,
      checkedIds: ipCheckIds,
      onCheck: ipOnCheck,
      checkAll: ipCheckAll
    } = useFilterCheckbox(ipCheckboxOptions, ipInitialCheckedIds)

    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    // 提交篩選
    const submitFilter = () => {
      // FIXME: 這裡提供了文字輸入、勾選、自訂篩選組的值，可以做後續組裡
      console.log('textInput: ' + textInput.value)
      console.log('searchTypeValue: ' + searchTypeValue.value)
      console.log('hostCheckIds: ' + hostCheckIds.value)
      console.log('ipCheckIds: ' + ipCheckIds.value)
      console.log('filterUnitList: ' + filterUnitList.value)
    }

    // Grid 相關
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler
    } = useGridInHost()

    onMounted(() => {
      dataItems.value = createRandomData(255) as never[]
    })

    // cell default template 取得此欄位 fieldName 對應的值
    const getNestedValue = (fieldName, dataItem) => {
      const path = fieldName.split('.')
      let data = dataItem
      path.forEach(p => {
        data = data ? data[p] : undefined
      })

      return data
    }

    // ContextMenu 相關 (for Kendo Grid)
    const gridMenuData = [
      {
        name: 'IP 政策設定',
        node: [
          { name: '取消 IP 封鎖' },
          { name: '設定固定 IP' },
          { name: '取消重要 IP' }
        ]
      },
      {
        name: 'MAC 政策設定'
      },
      {
        name: 'IP + MAC 政策'
      },
      {
        name: '符規檢查'
      },
      {
        name: '白名單 + MAC 政策'
      },
      { name: '設定 GLBP' },
      { name: '取消 GLBP' },
      { name: '詳細資訊' }
    ]
    const onGridMenuOpen = e => {
      // Fires before a sub-menu or the ContextMenu is opened
      // CHECK: (https://www.telerik.com/kendo-vue-ui/components/layout/api/contextmenu/ContextMenu/#toc-open)
      // 取得此欄位 td 的 ['data-id']
      const Id = e.target.getAttribute('data-id')
      console.log('Id: ' + Id)
      // 觸發欄位 select
      e.target.click()
    }

    // Dialog 相關
    // 顯示設定
    const {
      show: showDisplaySettings,
      toggle: toggleDisplaySettings
    } = useToggle()

    // 註冊單一區域主機
    const { show: showSingleHost, toggle: toggleSingleHost } = useToggle()
    // 註冊多個區域主機
    const { show: showMultiHost, toggle: toggleMultiHost } = useToggle()
    const registerHost = value => {
      value === 'single' ? toggleSingleHost() : toggleMultiHost()
    }

    return {
      // TreeView 相關
      template,
      localDataSource,
      isTreeViewOpen,
      treeViewRef,
      onTreeViewCheck,
      // 篩選相關：文字輸入
      textInput,
      submitFilter,
      // 篩選相關：檢索 單選選項
      searchTypeRadioOptions,
      searchTypeValue,
      searchTypeName,
      // 篩選相關：主機狀態 多選選項
      hostCheckboxOptions,
      hostCheckIds,
      hostAllChecked,
      hostOnCheck,
      hostCheckAll,
      // 篩選相關：IP 狀態 多選選項
      ipCheckboxOptions,
      ipCheckIds,
      ipAllChecked,
      ipOnCheck,
      ipCheckAll,
      // 篩選相關：自訂篩選
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit,
      // Grid 相關
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler,
      getNestedValue,
      gridMenuData,
      onGridMenuOpen,
      // Dialog 相關：顯示設定
      showDisplaySettings,
      toggleDisplaySettings,
      // Dialog 相關：註冊單一區域主機
      registerHost,
      showSingleHost,
      toggleSingleHost,
      // Dialog 相關：註冊多個區域主機
      showMultiHost,
      toggleMultiHost
    }
  }
})
