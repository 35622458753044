// 主機列表

// 1. TreeView 相關
const treeViewData = [
  {
    id: 0,
    name: '全部',
    isParent: true,
    icons: [],
    count: 2392,
    expanded: true,
    childrens: [
      {
        id: 1,
        name: 'Kevin Office',
        isParent: false,
        icons: ['tree-i-site'],
        count: 1340
      },
      {
        id: 2,
        name: 'Taipei Office',
        isParent: true,
        icons: ['tree-i-site'],
        count: 1020,
        expanded: true,
        childrens: [
          {
            id: 3,
            name: 'R&D Department',
            isParent: true,
            icons: ['tree-i-network'],
            count: 1020,
            childrens: [
              {
                id: 4,
                name: '192.168.67.X',
                icons: ['tree-i-ip'],
                count: 43
              }
            ]
          },
          {
            id: 5,
            name: 'R&D Department',
            isParent: true,
            icons: ['tree-i-network'],
            count: 1020,
            expanded: true,
            childrens: [
              {
                id: 6,
                name: '192.168.67.X',
                icons: ['tree-i-ip'],
                count: 40
              }
            ]
          },
          {
            id: 7,
            name: 'Test',
            isParent: false,
            icons: ['tree-i-network'],
            count: 32
          }
        ]
      }
    ]
  }
]

const treeViewSchema = {
  model: {
    id: 'id',
    hasChildren: 'isParent',
    children: 'childrens',
    schema: {
      model: {
        id: 'id',
        hasChildren: 'isParent'
      }
    }
  }
}

// 2. 篩選相關
// 所有檢索 Radio 選項
const searchTypeRadioOptions = [
  { id: 'Mac', name: 'MAC 檢索', divider: false },
  { id: 'MacAndIP', name: 'MAC + IP 檢索', divider: false }
]

// 起始的檢索 Radio 選項
const searchTypeInitialValue = 'MacAndIP'

// 所有主機狀態 checkbox 選項
const hostCheckboxOptions = [
  { id: 'MacBlockAdmin', name: 'MAC 封鎖 (管理者) ', divider: false },
  {
    id: 'UnauthorizedMacBlockGuest',
    name: '未授權 MAC 封鎖（等待覆核設備 - 訪客登入）',
    divider: false
  },
  {
    id: 'UnauthorizedMacBlockGeneral',
    name: '未授權 MAC 封鎖（等待覆核設備 - 一般登入）',
    divider: false
  },
  { id: 'PrivilegedMac', name: '特權 MAC', divider: false },
  { id: 'NonCompliantDeviceBlock', name: '未符規設備封鎖', divider: true },
  { id: 'IpConflict', name: 'IP 衝突', divider: false },
  { id: 'ChangeIpInViolation', name: '違規變更 IP', divider: false },
  { id: 'IpBlockAdmin', name: 'IP 封鎖 (管理者) ', divider: false },
  { id: 'UnauthorizedIpBlock', name: '未授權 IP 封鎖', divider: false }
]
// 起始的主機狀態勾選 checkbox 選項
const hostInitialCheckedIds = [
  'MacBlockAdmin',
  'UnauthorizedMacBlockGeneral',
  'IpConflict',
  'ChangeIpInViolation'
]

// 所有 IP 狀態 checkbox 選項
const ipCheckboxOptions = [
  { id: 'Online', name: '上線', divider: false },
  { id: 'Offline', name: '下線', divider: false },
  { id: 'Dhcp', name: 'DHCP', divider: false },
  { id: 'Static', name: '固定', divider: false }
]

const ipInitialCheckedIds = ['Online']

// 可用的篩選條件：FilterUnitAdd.vue 元件
const keyWithOptions = [
  { key: '作業系統', options: ['IOS', 'Windows', 'MacOS', 'Linux'] },
  { key: '網域群組', options: [] },
  { key: '訪客姓名', options: [] }
]
// 起始的篩選組合：FilterUnitDeletable.vue 元件
const initialFilterUnitList = [
  {
    id: 0,
    key: '作業系統',
    operator: '==',
    value: 'IOS',
    options: ['IOS', 'Windows', 'MacOS', 'Linux'] // 值有選項，會渲染下拉選單
  },
  {
    id: 1, // new Date().getUTCMilliseconds()
    key: '網域群組',
    operator: '!=',
    value: '台北分部',
    options: [] // 值沒有選項，會渲染文字輸入框
  }
]

export {
  treeViewData,
  treeViewSchema,
  searchTypeRadioOptions,
  searchTypeInitialValue,
  hostCheckboxOptions,
  hostInitialCheckedIds,
  ipCheckboxOptions,
  ipInitialCheckedIds,
  keyWithOptions,
  initialFilterUnitList
}
