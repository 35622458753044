<template>
  <div class="form-wrapper">
    <form>
      <div class="feild-group-title">
        <h3>填寫 MAC 資料</h3>
      </div>
      <div class="single-feild-group">
        <label class="feild-name">區域</label>
        <FeildInputGroup :isInvalid="true" :errorMsg="'輸入錯誤'">
          <MultiSelect
            style="width: 400px;"
            v-model="macData.multiSites"
            :data-source="siteArray"
            :data-text-field="'title'"
            :data-value-field="'id'"
            :placeholder="' - 請選擇 - '"
          />
        </FeildInputGroup>
      </div>
      <div class="single-feild-group">
        <label class="feild-name">MAC</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
          <BaseInput
            type="text"
            style="width: 400px;"
            v-model="macData.macAddress"
            required
          />
        </FeildInputGroup>
      </div>
      <div class="single-feild-group">
        <label class="feild-name">主機名稱</label>
        <FeildInputGroup :isInvalid="false" :errorMsg="'輸入錯誤'">
          <BaseInput
            type="text"
            style="width: 400px;"
            v-model="macData.hostName"
            required
          />
        </FeildInputGroup>
      </div>
      <div class="single-feild-group">
        <label class="feild-name">Mac 政策</label>
        <div>
          <div class="mb-2">
            <BaseCheckbox class="form-checkbox" v-model="macData.privilegedMac"
              >特權 MAC</BaseCheckbox
            >
          </div>
          <div class="mb-3 d-flex align-items-center">
            <BaseCheckbox
              class="form-checkbox"
              v-model="macData.limitedTimeAuth"
              >限期授權
              <span v-if="macData.limitedTimeAuth">，日期區間</span>
            </BaseCheckbox>
            <DateTimePicker
              v-if="macData.limitedTimeAuth"
              :format="'yyyy/MM/dd hh:mm:ss a'"
              style="width: 215px; margin-left: 14px;"
            />
            <DateTimePicker
              v-if="macData.limitedTimeAuth"
              :format="'yyyy/MM/dd hh:mm:ss a'"
              style="width: 215px; margin-left: 0px;"
            />
          </div>
        </div>
      </div>
      <div class="feild-group-title">
        <h3>填寫 擴充欄位 資料</h3>
        <span class="tip">
          <SvgIcon icon="warning" />
          非必填
        </span>
      </div>
      <div class="single-feild-group">
        <label class="feild-name">訪客姓名</label>
        <BaseInput
          type="text"
          style="width: 173px;"
          v-model="extensionData.visiterName"
        />
        <label class="feild-name">所屬部門</label>
        <BaseInput
          type="text"
          style="width: 173px;"
          v-model="extensionData.department"
        />
      </div>
      <div class="single-feild-group">
        <label class="feild-name">使用者主管</label>
        <BaseInput
          type="text"
          style="width: 173px;"
          v-model="extensionData.userSupervisor"
        />
        <label class="feild-name">使用者分機</label>
        <BaseInput
          type="text"
          style="width: 173px;"
          v-model="extensionData.userExtension"
        />
      </div>
      <div class="single-feild-group">
        <label class="feild-name">所屬單位</label>
        <BaseInput
          type="text"
          style="width: 173px;"
          v-model="extensionData.affiliation"
        />
        <label class="feild-name">防毒版本</label>
        <BaseInput
          type="text"
          style="width: 173px;"
          v-model="extensionData.antivirusVersion"
        />
      </div>
      <div class="single-feild-group">
        <label class="feild-name">病毒碼</label>
        <BaseInput
          type="text"
          style="width: 173px;"
          v-model="extensionData.virusPattern"
        />
        <label class="feild-name">防毒最後更新時間</label>
        <BaseInput
          type="text"
          style="width: 173px;"
          v-model="extensionData.antivirusLastUpdateTime"
        />
      </div>
      <div class="single-feild-group mb-5">
        <label class="feild-name">資源管理平台 安裝</label>
        <BaseInput
          type="text"
          style="width: 173px;"
          v-model="extensionData.rMPInstall"
        />
        <label class="feild-name">財產編號</label>
        <BaseInput
          type="text"
          style="width: 173px;"
          v-model="extensionData.propertyNumber"
        />
      </div>
      <div class="footer-group">
        <button
          type="submit"
          class="btn btn-primary form-btn mr-2"
          @click.prevent="submitForm"
        >
          完成設定
        </button>
        <button class="btn form-btn-outline" @click.prevent="$emit('close')">
          取消
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue'
import '@progress/kendo-ui/js/kendo.multiselect'
import { MultiSelect } from '@progress/kendo-dropdowns-vue-wrapper'
import BaseInput from '@/components/Input/BaseInput.vue'
import BaseCheckbox from '@/components/Checkbox/BaseCheckbox.vue'
import { DateTimePicker } from '@progress/kendo-vue-dateinputs'
import FeildInputGroup from '@/components/Form/FeildInputGroup.vue'

export default defineComponent({
  components: {
    MultiSelect,
    BaseInput,
    BaseCheckbox,
    DateTimePicker,
    FeildInputGroup
  },
  setup() {
    // 區域欄位
    const siteArray = ref([
      { id: 0, title: 'Taipei Office' },
      { id: 1, title: 'San Jose Office' },
      { id: 2, title: '宜蘭政府事務處' },
      { id: 3, title: '宜蘭市政府環保處' },
      { id: 4, title: '宜蘭政府衛生處' },
      { id: 5, title: '宜蘭政府觀光處' }
    ])
    // 網段欄位
    const networkArray = ref([
      { id: 0, title: '業務課' },
      { id: 1, title: '資訊室' },
      { id: 2, title: '會計室' },
      { id: 3, title: 'VLAN1' }
    ])
    const ipv4Array = Array(255)
      .fill({})
      .map((_, idx) => ({ id: idx + 1, title: `192.168.12.${idx + 1}` }))

    // MAC 資料
    const macData = reactive({
      multiSites: [],
      macAddress: '',
      hostName: '',
      privilegedMac: true,
      limitedTimeAuth: true
    })

    const extensionData = reactive({
      visiterName: '',
      department: '',
      userSupervisor: '',
      userExtension: '',
      affiliation: '',
      antivirusVersion: '',
      virusPattern: '',
      antivirusLastUpdateTime: '',
      rMPInstall: '',
      propertyNumber: ''
    })

    const submitForm = () => {
      console.log('macData:', macData)
      console.log('extensionData:', extensionData)
    }

    return {
      siteArray,
      macData,
      submitForm,
      networkArray,
      ipv4Array,
      extensionData
    }
  }
})
</script>

<style scoped></style>
