<template>
  <input
    v-bind="$attrs"
    class="form-control base-input"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number],
      default: ''
    }
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
