<template>
  <div class="filter-unit" ref="btn" @click="togglePopup">
    <span class="filter-unit-key mr-0">{{ valueName }}</span>
  </div>

  <teleport to="#popup-container">
    <div
      class="filter-popup no-hr-padding"
      ref="popup"
      v-show="isOpen"
      style="max-width: 366px; min-width: 182px;"
    >
      <div class="triangle"></div>
      <h5 class="title">{{ keyName }}</h5>
      <slot />
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useFilterPopup from '@/composable/useFilterPopup'

export default defineComponent({
  props: {
    keyName: String,
    valueName: String
  },
  setup() {
    const { isOpen, togglePopup, btn, popup } = useFilterPopup()

    return {
      isOpen,
      togglePopup,
      btn,
      popup
    }
  }
})
</script>

<style scoped></style>
