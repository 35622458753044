import { ref, computed } from 'vue'

// 搭配 FilterUnitRadio.vue 元件和裡面的 Radios 使用
const useFilterRadio = (RadioOptions, initialValue) => {
  // 被選中的值
  const currentValue = ref(initialValue)

  // 取得被選中的 Option 名稱
  const currentName = computed(() => {
    let result = ''
    RadioOptions.forEach(option => {
      if (option.id == currentValue.value) {
        result = option.name
      }
    })
    return result
  })

  return {
    currentValue,
    currentName
  }
}

export default useFilterRadio
